<template>
    <form @submit.prevent="submit" class="styled-form">

      <!-- Ticketnumber -->
      <div class="form-row">
        <label for="ticketnumber">{{ $t("Ticket number") }}</label>
        <input v-model="$v.ticketnumber.$model" :disabled="formLoading" type="text" name="ticketnumber" id="ticketnumber">
        <span class="error" v-if="$v.ticketnumber.$dirty && !$v.ticketnumber.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- Email -->
      <div class="form-row">
        <label for="email">{{ $t("Email address") }}</label>
        <input v-model="$v.email.$model" :disabled="formLoading" type="email" name="email" id="email">
        <span class="error" v-if="$v.email.$dirty && !$v.email.required">{{ $t("Field is required") }}</span>
        <span class="error" v-else-if="$v.email.$dirty && !$v.email.email">{{ $t("This is not a correct email address") }}</span>
      </div>

      <!-- Name -->
      <div class="form-row">
        <label for="name">{{ $t("Name") }}</label>
        <input v-model="$v.name.$model" :disabled="formLoading" type="text" name="name" id="name">
        <span class="error" v-if="$v.name.$dirty && !$v.name.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- Address -->
      <div class="form-row">
        <label for="address">{{ $t("Address line") }}</label>
        <input v-model="$v.address.$model" :disabled="formLoading" type="text" address="address" id="address">
        <span class="error" v-if="$v.address.$dirty && !$v.address.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- City -->
      <div class="form-row">
        <label for="city">{{ $t("City") }}</label>
        <input v-model="$v.city.$model" :disabled="formLoading" type="text" name="city" id="city">
        <span class="error" v-if="$v.city.$dirty && !$v.city.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- State/Provice -->
      <div class="form-row">
        <label for="state">{{ $t("State/Provice") }}</label>
        <input v-model="$v.state.$model" :disabled="formLoading" type="text" name="state" id="state">
        <span class="error" v-if="$v.state.$dirty && !$v.state.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- Zipcode -->
      <div class="form-row">
        <label for="zipcode">{{ $t("Zip/Postal Code") }}</label>
        <input v-model="$v.zipcode.$model" :disabled="formLoading" type="text" name="zipcode" id="zipcode">
        <span class="error" v-if="$v.zipcode.$dirty && !$v.zipcode.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- Country -->
      <div class="form-row">
        <label for="country">{{ $t("Country") }}</label>
        <input v-model="$v.country.$model" :disabled="formLoading" type="text" name="country" id="country">
        <span class="error" v-if="$v.country.$dirty && !$v.country.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- GDPR -->
      <div class="form-row checkbox">
        <input v-model="$v.gdprConsent.$model" type="checkbox" :disabled="formLoading" name="gdpr" id="gdpr">
        <label for="gdpr">{{ $t("I have read and understood the") }} <router-link :to="{name: 'Privacy'}" target="_blank">{{ $t("Privacy Statement") }}</router-link> {{ $t("and I agree to the") }} <router-link :to="{name: 'TermsOfUse'}" target="_blank">{{ $t("Terms of Use") }}</router-link></label>
        <span class="error" v-if="$v.gdprConsent.$dirty && !$v.gdprConsent.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- Recaptcha -->
      <div class="form-row">
         <vue-recaptcha sitekey="6LeORZccAAAAAO-S_MMoHiHOjfD5pS2G2uPUDYzo" ref="recaptcha" @verify="onVerifyRecaptcha" @expired="recaptcha = ''"></vue-recaptcha>
         <span class="error" v-if="$v.recaptcha.$dirty && !$v.recaptcha.required">{{ $t("Field is required") }}</span>
      </div>

      <div class="form-row" v-if="formErrors.length > 0 && !$v.$anyDirty">
        <span class="error" v-for="error in formErrors" :key="error.description">{{ $t(error.description) }}</span>
      </div>

      <div class="form-row">
        <input type="submit" :disabled="formLoading" class="btn btn-primary" :class="formLoading?'loading':''" :value="$t('Submit form')">
      </div>

      <div class="form-row">
        <p>{{ $t("Don't agree with the replace proposition") }}? <router-link :to="{ name: 'Reject' }">{{ $t("Reject request here") }}</router-link></p>
      </div>

    </form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import ApiService from '@/services/api.service'
import router from '@/router/'

import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'ReplaceForm',
  props: {
    guid: String
  },
  components: {
    VueRecaptcha
  },
  data () {
    return {
      ticketnumber: null,
      email: '',
      name: '',
      address: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
      recaptcha: '',
      gdprConsent: false,
      formLoading: false,
      formErrors: []
    }
  },
  validations: {
    ticketnumber: { required },
    name: { required },
    email: { required, email },
    address: { required },
    city: { required },
    state: { required },
    zipcode: { required },
    country: { required },
    recaptcha: { required },
    gdprConsent: {
      required: function (value) {
        if (value) {
          return true
        } else {
          return false
        }
      }
    }
  },
  beforeCreate () {},
  mounted () {},
  computed: {},
  methods: {
    onVerifyRecaptcha: function (response) {
      this.recaptcha = response
    },
    submit () {
      this.formLoading = true
      this.formError = null
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()

        ApiService.post('/Recaptcha', {
          token: this.recaptcha
        })
          .then(response => {
            if (response.data.success && response.data.data.result.valid) {
              this.replace()
            } else if (response.data.errors.length > 0) {
              this.formLoading = false
              this.formErrors = response.data.errors
            } else {
              this.formLoading = false
              this.formErrors = [{ description: 'Something went wrong. Please try again.' }]
            }
            this.$refs.recaptcha.reset()
          })
          .catch(error => {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
              this.formLoading = false
              this.formErrors = error.response.data.errors
            } else {
              this.formLoading = false
              this.formErrors = [{ description: 'Something went wrong. Please try again.' }]
            }
            this.$refs.recaptcha.reset()
          })
      } else {
        this.formLoading = false
      }
    },
    replace () {
      this.formLoading = true
      this.formError = null
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()
        ApiService.put('/Tickets/Replace', {
          Id: parseInt(this.ticketnumber),
          Guid: this.guid,
          Email: this.email,
          Name: this.name,
          Address: this.address,
          City: this.city,
          State: this.state,
          ZipCode: this.zipcode,
          Country: this.country,
          GdprConsent: this.gdprConsent
        })
          .then(response => {
            if (response.data.success) {
              this.$v.$reset()
              router.push({ name: 'ReplaceSuccess' })
            } else if (response.data.errors.length > 0) {
              this.formLoading = false
              this.formErrors = response.data.errors
            } else {
              this.formLoading = false
              this.formErrors = [{ description: 'Something went wrong. Please try again.' }]
            }
            this.$refs.recaptcha.reset()
          })
          .catch(error => {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
              this.formLoading = false
              this.formErrors = error.response.data.errors
            } else {
              this.formLoading = false
              this.formErrors = [{ description: 'Something went wrong. Please try again.' }]
            }
            this.$refs.recaptcha.reset()
          })
      } else {
        this.formLoading = false
        this.$refs.recaptcha.reset()
      }
    }
  },
  watch: {}
}
</script>
